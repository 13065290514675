html, body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}

section {
    padding: 60px 0;
}
