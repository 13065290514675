.navbar {
    background-color: #333;
    padding: 1rem;
}

.navbar ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
}

.navbar ul li {
    display: inline;
}

.navbar ul li a {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.navbar ul li a:hover {
    background-color: #555;
}
